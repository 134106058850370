export const grey = {
  900: '#2B3445',
  // Main Text
  800: '#373F50',
  // Paragraph
  700: '#4B566B',
  600: '#7D879C',
  // Low Priority form Title/Text
  500: '#AEB4BE',
  400: '#DAE1E7',
  // Border
  300: '#E3E9EF',
  200: '#F3F5F9',
  // Line Stroke
  100: '#F6F9FC'
};
export const primary = {
  100: '#171554',
  200: '#171554',
  300: '#171554',
  400: '#171554',
  500: '#171554',
  600: '#171554',
  700: '#171554',
  800: '#171554',
  900: '#171554',
  main: '#171554'
};
export const secondary = {
  100: '#fd5b72',
  200: '#fd5b72',
  300: '#fd5b72',
  400: '#fd5b72',
  500: '#fd5b72',
  600: '#fd5b72',
  700: '#fd5b72',
  800: '#fd5b72',
  900: '#fd5b72',
  main: '#fd5b72',
  dark: '#040D40'
};
export const error = {
  100: '#FFEAEA',
  200: '#FFCBCB',
  300: '#FFA9A9',
  400: '#FF6D6D',
  500: '#FF5353',
  600: '#FF4C4C',
  700: '#FF4242',
  800: '#FF3939',
  900: '#FF2929',
  main: '#E94560'
};
export const success = {
  100: '#28d797',
  200: '#C2F1D1',
  300: '#99E8B3',
  400: '#52D77E',
  500: '#33D067',
  600: '#2ECB5F',
  700: '#27C454',
  800: '#20BE4A',
  900: '#0b7724',
  main: 'rgb(51, 208, 103)'
};
export const warning = {
  main: '#FFCD4E',
  contrastText: '#FFFFFF'
};