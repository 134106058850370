// components/BotChat.js
'use client'
import Script from 'next/script';
import React, { useEffect } from 'react';


const BotaliteChat = () => {

    const [scriptLoaded, setScriptLoaded] = React.useState(false);

    useEffect(() => {
        if (!scriptLoaded) return;
        window.botpressWebChat.init({ host: "https://bots.botalite.es", botId: "decheckers-web" })
    }, [scriptLoaded]);


    return <>
        <Script src='https://bots.botalite.es/assets/modules/channel-web/inject.js' onLoad={() => setScriptLoaded(true)}></Script>
    </>
};

export default BotaliteChat;
